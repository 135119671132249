import {inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";

const baseUrl = environment.restUrl + '/api/branches';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  private http = inject(HttpClient)

  getBranches() {
    return lastValueFrom(
      this.http.get<any[]>(baseUrl)
    )
  }
}
